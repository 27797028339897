import { ref as z, computed as M, toRaw as T, watch as O, onBeforeMount as U, openBlock as A, createElementBlock as C, normalizeClass as N, createElementVNode as L, Fragment as j, renderList as E, unref as x, createVNode as q, TransitionGroup as Z, withCtx as B, toDisplayString as G, createCommentVNode as H, createTextVNode as R, Transition as J, normalizeStyle as K } from "vue";
(function(d) {
  typeof exports == "object" && typeof module < "u" ? module.exports = d() : typeof define == "function" && define.amd ? define([], d) : (typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : this).FunnelGraph = d();
})(function() {
  return function() {
    return function d(v, n, h) {
      function P(r, l) {
        if (!n[r]) {
          if (!v[r]) {
            var s = typeof require == "function" && require;
            if (!l && s)
              return s(r, !0);
            if (_)
              return _(r, !0);
            var o = new Error("Cannot find module '" + r + "'");
            throw o.code = "MODULE_NOT_FOUND", o;
          }
          var c = n[r] = { exports: {} };
          v[r][0].call(c.exports, function(m) {
            return P(v[r][1][m] || m);
          }, c, c.exports, d, v, n, h);
        }
        return n[r].exports;
      }
      for (var _ = typeof require == "function" && require, u = 0; u < h.length; u++)
        P(h[u]);
      return P;
    };
  }()({ 1: [function(d, v, n) {
    v.exports = d("./src/js/main").default;
  }, { "./src/js/main": 3 }], 2: [function(d, v, n) {
    function h(u) {
      return (h = typeof Symbol == "function" && typeof Symbol.iterator == "symbol" ? function(r) {
        return typeof r;
      } : function(r) {
        return r && typeof Symbol == "function" && r.constructor === Symbol && r !== Symbol.prototype ? "symbol" : typeof r;
      })(u);
    }
    Object.defineProperty(n, "__esModule", { value: !0 }), n.defaultColors = n.removeAttrs = n.setAttrs = n.createSVGElement = n.areEqual = n.getDefaultColors = n.generateLegendBackground = void 0;
    var P = function(u, r) {
      h(r) === "object" && Object.keys(r).forEach(function(l) {
        u.setAttribute(l, r[l]);
      });
    };
    n.setAttrs = P, n.removeAttrs = function(u) {
      for (var r = arguments.length, l = new Array(r > 1 ? r - 1 : 0), s = 1; s < r; s++)
        l[s - 1] = arguments[s];
      l.forEach(function(o) {
        u.removeAttribute(o);
      });
    }, n.createSVGElement = function(u, r, l) {
      var s = document.createElementNS("http://www.w3.org/2000/svg", u);
      return h(l) === "object" && P(s, l), r !== void 0 && r.appendChild(s), s;
    }, n.generateLegendBackground = function(u) {
      var r = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : "horizontal";
      return typeof u == "string" ? "background-color: ".concat(u) : u.length === 1 ? "background-color: ".concat(u[0]) : "background-image: linear-gradient(".concat(r === "horizontal" ? "to right, " : "").concat(u.join(", "), ")");
    };
    var _ = ["#FF4589", "#FF5050", "#05DF9D", "#4FF2FD", "#2D9CDB", "#A0BBFF", "#FFD76F", "#F2C94C", "#FF9A9A", "#FFB178"];
    n.defaultColors = _, n.getDefaultColors = function(u) {
      for (var r = [].concat(_), l = [], s = 0; s < u; s++) {
        var o = Math.abs(Math.round(Math.random() * (r.length - 1)));
        l.push(r[o]), r.splice(o, 1);
      }
      return l;
    }, n.areEqual = function(u, r) {
      var l = Object.prototype.toString.call(u);
      if (l !== Object.prototype.toString.call(r) || l !== "[object Array]" || u.length !== r.length)
        return !1;
      for (var s = 0; s < u.length; s++) {
        var o = Object.prototype.toString.call(u[s]);
        if (o !== Object.prototype.toString.call(r[s]))
          return !1;
        if (o === "[object Array]") {
          if (u[s].length !== r[s].length)
            return !1;
          for (var c = 0; c < u[s].length; c++)
            if (u[s][c] !== r[s][c])
              return !1;
        } else if (u[s] !== r[s])
          return !1;
      }
      return !0;
    };
  }, {}], 3: [function(d, v, n) {
    Object.defineProperty(n, "__esModule", { value: !0 }), n.default = void 0;
    var h = d("./number"), P = d("./path"), _ = d("./graph");
    function u(o) {
      return (u = typeof Symbol == "function" && typeof Symbol.iterator == "symbol" ? function(c) {
        return typeof c;
      } : function(c) {
        return c && typeof Symbol == "function" && c.constructor === Symbol && c !== Symbol.prototype ? "symbol" : typeof c;
      })(o);
    }
    function r(o) {
      return function(c) {
        if (Array.isArray(c)) {
          for (var m = 0, p = new Array(c.length); m < c.length; m++)
            p[m] = c[m];
          return p;
        }
      }(o) || function(c) {
        if (Symbol.iterator in Object(c) || Object.prototype.toString.call(c) === "[object Arguments]")
          return Array.from(c);
      }(o) || function() {
        throw new TypeError("Invalid attempt to spread non-iterable instance");
      }();
    }
    function l(o, c) {
      for (var m = 0; m < c.length; m++) {
        var p = c[m];
        p.enumerable = p.enumerable || !1, p.configurable = !0, "value" in p && (p.writable = !0), Object.defineProperty(o, p.key, p);
      }
    }
    var s = function() {
      function o(e) {
        (function(t, a) {
          if (!(t instanceof a))
            throw new TypeError("Cannot call a class as a function");
        })(this, o), this.containerSelector = e.container, this.gradientDirection = e.gradientDirection && e.gradientDirection === "vertical" ? "vertical" : "horizontal", this.direction = e.direction && e.direction === "vertical" ? "vertical" : "horizontal", this.labels = o.getLabels(e), this.subLabels = o.getSubLabels(e), this.values = o.getValues(e), this.percentages = this.createPercentages(), this.colors = e.data.colors || (0, _.getDefaultColors)(this.is2d() ? this.getSubDataSize() : 2), this.displayPercent = e.displayPercent || !1, this.data = e.data, this.height = e.height, this.width = e.width, this.subLabelValue = e.subLabelValue || "percent";
      }
      var c, m, p;
      return c = o, p = [{ key: "getSubLabels", value: function(e) {
        if (!e.data)
          throw new Error("Data is missing");
        var t = e.data;
        return t.subLabels === void 0 ? [] : t.subLabels;
      } }, { key: "getLabels", value: function(e) {
        if (!e.data)
          throw new Error("Data is missing");
        var t = e.data;
        return t.labels === void 0 ? [] : t.labels;
      } }, { key: "getValues", value: function(e) {
        if (!e.data)
          return [];
        var t = e.data;
        return u(t) === "object" ? t.values : [];
      } }], (m = [{ key: "getMainAxisPoints", value: function() {
        for (var e = this.getDataSize(), t = [], a = this.isVertical() ? this.getHeight() : this.getWidth(), f = 0; f <= e; f++)
          t.push((0, h.roundPoint)(a * f / e));
        return t;
      } }, { key: "getCrossAxisPoints", value: function() {
        var e = [], t = this.getFullDimension(), a = t / 2;
        if (this.is2d()) {
          var f = this.getValues2d(), y = Math.max.apply(Math, r(f));
          f.push(r(f).pop()), e.push(f.map(function(D) {
            return (0, h.roundPoint)((y - D) / y * a);
          }));
          for (var S = this.getPercentages2d(), i = e[0], g = 1; g < this.getSubDataSize(); g++) {
            for (var k = e[g - 1], b = [], w = 0; w < this.getDataSize(); w++)
              b.push((0, h.roundPoint)(k[w] + (t - 2 * i[w]) * (S[w][g - 1] / 100)));
            b.push([].concat(b).pop()), e.push(b);
          }
          e.push(i.map(function(D) {
            return t - D;
          }));
        } else {
          var V = Math.max.apply(Math, r(this.values)), F = r(this.values).concat(r(this.values).pop());
          e.push(F.map(function(D) {
            return (0, h.roundPoint)((V - D) / V * a);
          })), e.push(e[0].map(function(D) {
            return t - D;
          }));
        }
        return e;
      } }, { key: "getGraphType", value: function() {
        return this.values && this.values[0] instanceof Array ? "2d" : "normal";
      } }, { key: "is2d", value: function() {
        return this.getGraphType() === "2d";
      } }, { key: "isVertical", value: function() {
        return this.direction === "vertical";
      } }, { key: "getDataSize", value: function() {
        return this.values.length;
      } }, { key: "getSubDataSize", value: function() {
        return this.values[0].length;
      } }, { key: "getFullDimension", value: function() {
        return this.isVertical() ? this.getWidth() : this.getHeight();
      } }, { key: "addLabels", value: function() {
        var e = this, t = document.createElement("div");
        t.setAttribute("class", "svg-funnel-js__labels"), this.percentages.forEach(function(a, f) {
          var y = document.createElement("div");
          y.setAttribute("class", "svg-funnel-js__label label-".concat(f + 1));
          var S = document.createElement("div");
          S.setAttribute("class", "label__title"), S.textContent = e.labels[f] || "";
          var i = document.createElement("div");
          i.setAttribute("class", "label__value");
          var g = e.is2d() ? e.getValues2d()[f] : e.values[f];
          i.textContent = (0, h.formatNumber)(g);
          var k = document.createElement("div");
          if (k.setAttribute("class", "label__percentage"), a !== 100 && (k.textContent = "".concat(a.toString(), "%")), y.appendChild(i), y.appendChild(S), e.displayPercent && y.appendChild(k), e.is2d()) {
            var b = document.createElement("div");
            b.setAttribute("class", "label__segment-percentages");
            var w = '<ul class="segment-percentage__list">', V = e.getPercentages2d();
            e.subLabels.forEach(function(F, D) {
              var I = e.subLabelValue === "percent" ? "".concat(V[f][D], "%") : (0, h.formatNumber)(e.values[f][D]);
              w += "<li>".concat(e.subLabels[D], `:
    <span class="percentage__list-label">`).concat(I, `</span>
 </li>`);
            }), w += "</ul>", b.innerHTML = w, y.appendChild(b);
          }
          t.appendChild(y);
        }), this.container.appendChild(t);
      } }, { key: "addSubLabels", value: function() {
        var e = this;
        if (this.subLabels) {
          var t = document.createElement("div");
          t.setAttribute("class", "svg-funnel-js__subLabels");
          var a = "";
          this.subLabels.forEach(function(f, y) {
            a += '<div class="svg-funnel-js__subLabel svg-funnel-js__subLabel-'.concat(y + 1, `">
    <div class="svg-funnel-js__subLabel--color" 
        style="`).concat((0, _.generateLegendBackground)(e.colors[y], e.gradientDirection), `"></div>
    <div class="svg-funnel-js__subLabel--title">`).concat(f, `</div>
</div>`);
          }), t.innerHTML = a, this.container.appendChild(t);
        }
      } }, { key: "createContainer", value: function() {
        if (!this.containerSelector)
          throw new Error("Container is missing");
        this.container = document.querySelector(this.containerSelector), this.container.classList.add("svg-funnel-js"), this.graphContainer = document.createElement("div"), this.graphContainer.classList.add("svg-funnel-js__container"), this.container.appendChild(this.graphContainer), this.direction === "vertical" && this.container.classList.add("svg-funnel-js--vertical");
      } }, { key: "setValues", value: function(e) {
        return this.values = e, this;
      } }, { key: "setDirection", value: function(e) {
        return this.direction = e, this;
      } }, { key: "setHeight", value: function(e) {
        return this.height = e, this;
      } }, { key: "setWidth", value: function(e) {
        return this.width = e, this;
      } }, { key: "getValues2d", value: function() {
        var e = [];
        return this.values.forEach(function(t) {
          e.push(t.reduce(function(a, f) {
            return a + f;
          }, 0));
        }), e;
      } }, { key: "getPercentages2d", value: function() {
        var e = [];
        return this.values.forEach(function(t) {
          var a = t.reduce(function(f, y) {
            return f + y;
          }, 0);
          e.push(t.map(function(f) {
            return a === 0 ? 0 : (0, h.roundPoint)(100 * f / a);
          }));
        }), e;
      } }, { key: "createPercentages", value: function() {
        var e = [];
        e = this.is2d() ? this.getValues2d() : r(this.values);
        var t = Math.max.apply(Math, r(e));
        return e.map(function(a) {
          return (0, h.roundPoint)(100 * a / t);
        });
      } }, { key: "applyGradient", value: function(e, t, a, f) {
        var y = e.querySelector("defs") === null ? (0, _.createSVGElement)("defs", e) : e.querySelector("defs"), S = "funnelGradient-".concat(f), i = (0, _.createSVGElement)("linearGradient", y, { id: S });
        this.gradientDirection === "vertical" && (0, _.setAttrs)(i, { x1: "0", x2: "0", y1: "0", y2: "1" });
        for (var g = a.length, k = 0; k < g; k++)
          (0, _.createSVGElement)("stop", i, { "stop-color": a[k], offset: "".concat(Math.round(100 * k / (g - 1)), "%") });
        (0, _.setAttrs)(t, { fill: 'url("#'.concat(S, '")'), stroke: 'url("#'.concat(S, '")') });
      } }, { key: "makeSVG", value: function() {
        for (var e = (0, _.createSVGElement)("svg", this.graphContainer, { width: this.getWidth(), height: this.getHeight() }), t = this.getCrossAxisPoints().length - 1, a = 0; a < t; a++) {
          var f = (0, _.createSVGElement)("path", e), y = this.is2d() ? this.colors[a] : this.colors, S = typeof y == "string" || y.length === 1 ? "solid" : "gradient";
          S === "solid" ? (0, _.setAttrs)(f, { fill: y, stroke: y }) : S === "gradient" && this.applyGradient(e, f, y, a + 1), e.appendChild(f);
        }
        this.graphContainer.appendChild(e);
      } }, { key: "getSVG", value: function() {
        var e = this.container.querySelector("svg");
        if (!e)
          throw new Error("No SVG found inside of the container");
        return e;
      } }, { key: "getWidth", value: function() {
        return this.width || this.graphContainer.clientWidth;
      } }, { key: "getHeight", value: function() {
        return this.height || this.graphContainer.clientHeight;
      } }, { key: "getPathDefinitions", value: function() {
        for (var e = this.getCrossAxisPoints().length - 1, t = [], a = 0; a < e; a++)
          if (this.isVertical()) {
            var f = this.getCrossAxisPoints()[a], y = this.getCrossAxisPoints()[a + 1], S = this.getMainAxisPoints(), i = (0, P.createVerticalPath)(a, f, y, S);
            t.push(i);
          } else {
            var g = this.getMainAxisPoints(), k = this.getCrossAxisPoints()[a], b = this.getCrossAxisPoints()[a + 1], w = (0, P.createPath)(a, g, k, b);
            t.push(w);
          }
        return t;
      } }, { key: "getPathMedian", value: function(e) {
        if (this.isVertical()) {
          var t = this.getCrossAxisPoints()[e], a = this.getCrossAxisPoints()[e + 1], f = this.getMainAxisPoints(), y = [], S = [];
          return t.forEach(function(V, F) {
            var D = (V + a[F]) / 2;
            y.push(D - 1), S.push(D + 1);
          }), (0, P.createVerticalPath)(e, y, S, f);
        }
        var i = this.getMainAxisPoints(), g = this.getCrossAxisPoints()[e], k = this.getCrossAxisPoints()[e + 1], b = [], w = [];
        return g.forEach(function(V, F) {
          var D = (V + k[F]) / 2;
          b.push(D - 1), w.push(D + 1);
        }), (0, P.createPath)(e, i, b, w);
      } }, { key: "drawPaths", value: function() {
        var e = this.getSVG().querySelectorAll("path");
        this.getPathDefinitions().forEach(function(t, a) {
          e[a].setAttribute("d", t);
        });
      } }, { key: "draw", value: function() {
        this.createContainer(), this.makeSVG(), this.addLabels(), this.is2d() && this.addSubLabels(), this.drawPaths();
      } }, { key: "makeVertical", value: function() {
        if (this.direction === "vertical")
          return !0;
        this.direction = "vertical", this.container.classList.add("svg-funnel-js--vertical");
        var e = this.getSVG(), t = this.getHeight(), a = this.getWidth();
        return (0, _.setAttrs)(e, { height: t, width: a }), this.drawPaths(), !0;
      } }, { key: "makeHorizontal", value: function() {
        if (this.direction === "horizontal")
          return !0;
        this.direction = "horizontal", this.container.classList.remove("svg-funnel-js--vertical");
        var e = this.getSVG(), t = this.getHeight(), a = this.getWidth();
        return (0, _.setAttrs)(e, { height: t, width: a }), this.drawPaths(), !0;
      } }, { key: "toggleDirection", value: function() {
        this.direction === "horizontal" ? this.makeVertical() : this.makeHorizontal();
      } }, { key: "gradientMakeVertical", value: function() {
        if (this.gradientDirection === "vertical")
          return !0;
        this.gradientDirection = "vertical";
        for (var e = this.graphContainer.querySelectorAll("linearGradient"), t = 0; t < e.length; t++)
          (0, _.setAttrs)(e[t], { x1: "0", x2: "0", y1: "0", y2: "1" });
        return !0;
      } }, { key: "gradientMakeHorizontal", value: function() {
        if (this.gradientDirection === "horizontal")
          return !0;
        this.gradientDirection = "horizontal";
        for (var e = this.graphContainer.querySelectorAll("linearGradient"), t = 0; t < e.length; t++)
          (0, _.removeAttrs)(e[t], "x1", "x2", "y1", "y2");
        return !0;
      } }, { key: "gradientToggleDirection", value: function() {
        this.gradientDirection === "horizontal" ? this.gradientMakeVertical() : this.gradientMakeHorizontal();
      } }, { key: "updateWidth", value: function(e) {
        this.width = e;
        var t = this.getSVG(), a = this.getWidth();
        return (0, _.setAttrs)(t, { width: a }), this.drawPaths(), !0;
      } }, { key: "updateHeight", value: function(e) {
        this.height = e;
        var t = this.getSVG(), a = this.getHeight();
        return (0, _.setAttrs)(t, { height: a }), this.drawPaths(), !0;
      } }, { key: "updateData", value: function(e) {
        e.labels !== void 0 && (this.container.querySelector(".svg-funnel-js__labels").remove(), this.labels = o.getLabels({ data: e }), this.addLabels()), e.colors !== void 0 && (this.colors = e.colors || (0, _.getDefaultColors)(this.is2d() ? this.getSubDataSize() : 2)), e.values !== void 0 && (Object.prototype.toString.call(e.values[0]) !== Object.prototype.toString.call(this.values[0]) ? (this.container.querySelector("svg").remove(), this.values = o.getValues({ data: e }), this.makeSVG(), this.drawPaths()) : (this.values = o.getValues({ data: e }), this.drawPaths())), e.subLabels !== void 0 && (this.container.querySelector(".svg-funnel-js__subLabels").remove(), this.subLabels = o.getSubLabels({ data: e }), this.addSubLabels());
      } }, { key: "update", value: function(e) {
        var t = this;
        e.displayPercent !== void 0 && this.displayPercent !== e.displayPercent && (this.displayPercent === !0 ? this.container.querySelectorAll(".label__percentage").forEach(function(a) {
          a.remove();
        }) : this.container.querySelectorAll(".svg-funnel-js__label").forEach(function(a, f) {
          var y = t.percentages[f], S = document.createElement("div");
          S.setAttribute("class", "label__percentage"), y !== 100 && (S.textContent = "".concat(y.toString(), "%"), a.appendChild(S));
        })), e.height !== void 0 && this.updateHeight(e.height), e.width !== void 0 && this.updateWidth(e.width), e.gradientDirection !== void 0 && (e.gradientDirection === "vertical" ? this.gradientMakeVertical() : e.gradientDirection === "horizontal" && this.gradientMakeHorizontal()), e.direction !== void 0 && (e.direction === "vertical" ? this.makeVertical() : e.direction === "horizontal" && this.makeHorizontal()), e.data !== void 0 && this.updateData(e.data);
      } }]) && l(c.prototype, m), p && l(c, p), o;
    }();
    n.default = s;
  }, { "./graph": 2, "./number": 4, "./path": 5 }], 4: [function(d, v, n) {
    Object.defineProperty(n, "__esModule", { value: !0 }), n.formatNumber = n.roundPoint = void 0, n.roundPoint = function(h) {
      return isNaN(h) && (h = 0), Math.round(10 * h) / 10;
    }, n.formatNumber = function(h) {
      return Number(h).toFixed().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    };
  }, {}], 5: [function(d, v, n) {
    Object.defineProperty(n, "__esModule", { value: !0 }), n.createVerticalPath = n.createPath = n.createVerticalCurves = n.createCurves = void 0;
    var h = d("./number");
    function P(r) {
      return function(l) {
        if (Array.isArray(l)) {
          for (var s = 0, o = new Array(l.length); s < l.length; s++)
            o[s] = l[s];
          return o;
        }
      }(r) || function(l) {
        if (Symbol.iterator in Object(l) || Object.prototype.toString.call(l) === "[object Arguments]")
          return Array.from(l);
      }(r) || function() {
        throw new TypeError("Invalid attempt to spread non-iterable instance");
      }();
    }
    var _ = function(r, l, s, o) {
      return " C".concat((0, h.roundPoint)((s + r) / 2), ",").concat(l, " ") + "".concat((0, h.roundPoint)((s + r) / 2), ",").concat(o, " ").concat(s, ",").concat(o);
    };
    n.createCurves = _;
    var u = function(r, l, s, o) {
      return " C".concat(r, ",").concat((0, h.roundPoint)((o + l) / 2), " ") + "".concat(s, ",").concat((0, h.roundPoint)((o + l) / 2), " ").concat(s, ",").concat(o);
    };
    n.createVerticalCurves = u, n.createPath = function(r, l, s, o) {
      for (var c = "M".concat(l[0], ",").concat(s[0]), m = 0; m < l.length - 1; m++)
        c += _(l[m], s[m], l[m + 1], s[m + 1]);
      c += " L".concat(P(l).pop(), ",").concat(P(o).pop());
      for (var p = l.length - 1; p > 0; p--)
        c += _(l[p], o[p], l[p - 1], o[p - 1]);
      return c += " Z";
    }, n.createVerticalPath = function(r, l, s, o) {
      for (var c = "M".concat(l[0], ",").concat(o[0]), m = 0; m < l.length - 1; m++)
        c += u(l[m], o[m], l[m + 1], o[m + 1]);
      c += " L".concat(P(s).pop(), ",").concat(P(o).pop());
      for (var p = l.length - 1; p > 0; p--)
        c += u(s[p], o[p], s[p - 1], o[p - 1]);
      return c += " Z";
    };
  }, { "./number": 4 }] }, {}, [1])(1);
});
const Q = [
  "#FF4589",
  "#FF5050",
  "#05DF9D",
  "#4FF2FD",
  "#2D9CDB",
  "#A0BBFF",
  "#FFD76F",
  "#F2C94C",
  "#FF9A9A",
  "#FFB178"
], W = (d) => {
  const v = [...Q], n = [];
  for (let h = 0; h < d; h++) {
    const P = Math.abs(Math.round(Math.random() * (v.length - 1)));
    n.push(v[P]), v.splice(P, 1);
  }
  return n;
}, X = (d, v = "horizontal") => typeof d == "string" ? `background-color: ${d}` : d.length === 1 ? `background-color: ${d[0]}` : `background-image: linear-gradient(${v === "horizontal" ? "to right, " : ""}${d.join(", ")})`, $ = (d) => Number(d).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"), Y = () => {
  try {
    if (FunnelGraph)
      return FunnelGraph;
    throw new Error("Missing dependency funnel-graph.min.js");
  } catch (d) {
    return console.error(d), null;
  }
}, ee = Y();
const te = { class: "svg-funnel-js__container" }, ne = ["width", "height"], re = ["id", "gradientTransform"], ae = ["stop-color", "offset"], ie = ["fill", "stroke", "d"], se = { class: "label__value" }, oe = {
  key: 0,
  class: "label__title"
}, le = {
  key: 1,
  class: "label__percentage"
}, ue = {
  key: 2,
  class: "label__segment-percentages"
}, ce = { class: "segment-percentage__list" }, he = { class: "percentage__list-label" }, de = {
  key: 0,
  class: "svg-funnel-js__subLabels"
}, fe = { class: "svg-funnel-js__subLabel--title" }, ve = {
  __name: "VueFunnelGraph",
  props: {
    height: {
      type: [Number, String],
      default: 300
    },
    width: {
      type: [Number, String],
      default: 800
    },
    colors: {
      type: Array,
      default: []
    },
    labels: {
      type: Array,
      default: []
    },
    subLabels: {
      type: Array,
      default: []
    },
    values: {
      type: Array,
      default: []
    },
    direction: {
      type: String,
      default: "horizontal"
    },
    gradientDirection: {
      type: String,
      default: "horizontal"
    },
    animated: {
      type: Boolean,
      default: !1
    },
    displayPercentage: {
      type: Boolean,
      default: !0
    }
  },
  setup(d) {
    const v = d, n = z(null), h = z([]), P = z([]), _ = z(W(10)), u = () => {
      var i;
      if ((i = n.value) != null && i.is2d)
        return n.value.is2d();
      throw new TypeError("Variable graph is not instance of VueFunnelGraph");
    }, r = () => {
      var i;
      if ((i = n.value) != null && i.getPathDefinitions)
        return n.value.getPathDefinitions();
      throw new TypeError("Variable graph is not instance of VueFunnelGraph");
    }, l = () => {
      var i;
      if ((i = n.value) != null && i.getValues2d)
        return n.value.getValues2d();
      throw new TypeError("Variable graph is not instance of VueFunnelGraph");
    }, s = () => {
      var i;
      if ((i = n.value) != null && i.createPercentages)
        return n.value.createPercentages();
      throw new TypeError("Variable graph is not instance of VueFunnelGraph");
    }, o = () => {
      var i;
      if (!u())
        return [];
      if ((i = n.value) != null && i.getPercentages2d)
        return n.value.getPercentages2d();
      throw new TypeError("Variable graph is not instance of VueFunnelGraph");
    }, c = (i) => u() ? X(
      t.value[i],
      v.gradientDirection
    ) : [], m = (i, g) => `${Math.round(100 * i / (g - 1))}%`, p = (i, g) => {
      !g || (v.animated || g(), setTimeout(() => g(), 700));
    }, e = () => {
      P.value = h.value, h.value = [], r().forEach((g) => {
        h.value.push(g);
      });
    }, t = M(() => {
      const i = T(v.colors), g = T(_.value);
      return i instanceof Array && i.length === 0 ? W(u() ? v.values.length : 2) : i.length < h.value.length ? i.concat(
        [...g].splice(
          h.value.length,
          h.value.length - i.length
        )
      ) : i;
    }), a = M(() => {
      const i = [];
      let g = 0;
      return h.value.map((k, b) => {
        const w = u() ? t.value[b] : t.value, V = typeof w == "string" || w.length === 1, F = V ? "solid" : "gradient";
        V || (g += 1), i.push({
          values: w,
          fillMode: F,
          fill: V ? w : `url('#funnelGradient-${g}')`
        });
      }), i;
    }), f = M(() => {
      const i = [];
      return a.value.map((g) => {
        g.fillMode === "gradient" && i.push(g);
      }), i;
    }), y = M(() => `rotate(${v.gradientDirection === "vertical" ? 90 : 0})`), S = M(() => u() ? l().map((k) => $(k)) : T(v.values).map((g) => $(g)));
    return O(
      () => v.values,
      () => {
        var i;
        if ((i = n.value) != null && i.setValues)
          return n.value.setValues(T(v.values)), e();
        throw new TypeError("Variable graph is not instance of VueFunnelGraph");
      }
    ), O(
      () => v.direction,
      () => {
        var i, g, k;
        if (((i = n.value) == null ? void 0 : i.setDirection) && ((g = n.value) == null ? void 0 : g.setWidth) && ((k = n.value) == null ? void 0 : k.setHeight))
          return n.value.setDirection(v.direction).setWidth(v.width).setHeight(v.height), e();
        throw new TypeError("Variable graph is not instance of VueFunnelGraph");
      }
    ), U(() => {
      n.value = new ee({
        height: v.height,
        width: v.width,
        direction: v.direction,
        data: {
          labels: v.labels,
          values: v.values
        }
      }), e();
    }), (i, g) => (A(), C("div", {
      class: N(["funnel svg-funnel-js", { "svg-funnel-js--vertical": d.direction === "vertical" }])
    }, [
      L("div", te, [
        (A(), C("svg", {
          width: d.width,
          height: d.height
        }, [
          L("defs", null, [
            (A(!0), C(j, null, E(x(f), (k, b) => (A(), C("linearGradient", {
              id: "funnelGradient-" + (b + 1),
              key: b,
              gradientTransform: x(y)
            }, [
              (A(!0), C(j, null, E(k.values, (w, V) => (A(), C("stop", {
                "stop-color": w,
                offset: m(V, k.values.length),
                key: V
              }, null, 8, ae))), 128))
            ], 8, re))), 128))
          ]),
          (A(!0), C(j, null, E(h.value, (k, b) => (A(), C("path", {
            fill: x(a)[b].fill,
            stroke: x(a)[b].fill,
            d: k,
            key: b
          }, null, 8, ie))), 128))
        ], 8, ne))
      ]),
      q(Z, {
        class: "svg-funnel-js__labels",
        name: "appear",
        tag: "div",
        onEnter: p,
        onLeave: p
      }, {
        default: B(() => [
          (A(!0), C(j, null, E(x(S), (k, b) => (A(), C("div", {
            class: N(["svg-funnel-js__label", "label-" + (b + 1)]),
            key: d.labels[b].toLowerCase().split(" ").join("-")
          }, [
            L("div", se, G(k), 1),
            d.labels ? (A(), C("div", oe, G(d.labels[b]), 1)) : H("", !0),
            d.displayPercentage && s()[b] !== 100 ? (A(), C("div", le, G(s()[b]) + "% ", 1)) : H("", !0),
            u() ? (A(), C("div", ue, [
              L("ul", ce, [
                (A(!0), C(j, null, E(d.subLabels, (w, V) => (A(), C("li", { key: V }, [
                  R(G(w) + ": ", 1),
                  L("span", he, G(o()[b][V]) + "%", 1)
                ]))), 128))
              ])
            ])) : H("", !0)
          ], 2))), 128))
        ]),
        _: 1
      }),
      q(J, {
        name: "fade",
        onEnter: p,
        onLeave: p
      }, {
        default: B(() => [
          u() ? (A(), C("div", de, [
            (A(!0), C(j, null, E(d.subLabels, (k, b) => (A(), C("div", {
              class: N(
                "svg-funnel-js__subLabel svg-funnel-js__subLabel-" + (b + 1)
              ),
              key: b
            }, [
              L("div", {
                class: "svg-funnel-js__subLabel--color",
                style: K(c(b))
              }, null, 4),
              L("div", fe, G(k), 1)
            ], 2))), 128))
          ])) : H("", !0)
        ]),
        _: 1
      })
    ], 2));
  }
};
export {
  ve as VueFunnelGraph
};
