import { cloneDeep } from 'lodash-es'
import { infoCardTypes as serverComponents } from '~~/sharedUtils/infoCardTypes'
import { useUserPermissionsClient } from '~~/sharedUtils/useUserPermissionsClient'

import { migrate, schemaVersion as serverComponentsVersion } from '~/utils/migrations'
import { useToast } from '#imports'

const localStorageKey = 'dynamicComponents'

export function state () {
  const { dynamicComponentsUseLocalStorage } = useRuntimeConfig().public
  return {
    isInitialised: false,
    useLocalStorage: dynamicComponentsUseLocalStorage,
    components: [],
  }
}

export const actions = {
  async initialise ({ commit, dispatch, state }, object) {
    const dynamicComponents = await dispatch('getDynamicComponents')
    commit('setComponents', dynamicComponents)
    state.isInitialised = true
  },
  getDynamicComponents ({ state }) {
    // This will either be the default components (sorted and filtered) or components loaded from local storage (when useLocalStorage is enabled)
    let components

    if (state.useLocalStorage) {
      console.log('Using local storage for dynamicComponents')
      components = localStorageGet(localStorageKey)
    }

    // If first run or if all components have been removed by the user
    // Filter default components then sort them
    if (!components || !components?.length) {
      console.log('Components not found in local storage, using default components')

      const order = [
        'Dash - Today\'s profit',
        'Enterprise - Net Revenue',
        'Enterprise - Budget OH',
        'Enterprise - Estimated market spend',
        'Enterprise - EBITDA Budget',
        'Enterprise - EBITDA Actual',
        'Enterprise - EBITDA Difference',

        'Enterprise - Clickout per hour',
        // 'EBITDA - Sum Net Revenue',
        // 'CSM - Estimated market spend',
        // 'EBITDA - Sum Budget OH',
        // 'EBITDA - Actual',
        // 'EBITDA - Budget',
        'CSM - Planned spend',
        'Enterprise - Day VS 2019',
        'ETraveli - Flygresor.se search market share',
        'Enterprise - OTA shares - Overview',
        'Enterprise - OTA shares - Non ETG brands',
        'Enterprise - Per click cost',
        'Enterprise - Day click rate',
        'Enterprise - Day searches',
        'Enterprise - Day clicks',
        'Dash - Time of profitability',
        'Google Spreadsheet - Net Revenue',
        'Google Spreadsheet - Marketing Costs',
        'Google Spreadsheet - EBITDA',
        'Google Spreadsheet - OH Cost',
        'Google Spreadsheet - OWC',
        'Google Spreadsheet - Top of Mind - Brand Awareness',
        'Google Spreadsheet - Top of Mind - Ad recall',
        'Google Spreadsheet - In mind - Brand Awareness',
        // 'EBITDA - Actual & Budget'
        // 'CSM - Planned vs Est',
        // 'CSM - Planned spend',
        // 'Enterprise - Searches per hour',
        // 'Enterprise - Day VS Last Year',
        // 'Enterprise - OTA shares - ETG brands'
      ]

      components = serverComponents
        .filter(item => order.includes(item.fullName))
        .sort((a, b) => order.indexOf(a.fullName) - order.indexOf(b.fullName))
    }

    if (state.useLocalStorage) {
      // Get version number from localStorage, default to serverComponentsVersion
      const localComponentsVersion = localStorage.getItem(`${localStorageKey}Version`) || serverComponentsVersion

      // Update localStorage dynamicComponents version
      localStorage.setItem(`${localStorageKey}Version`, serverComponentsVersion)

      // If the localVersion is outdated
      if (serverComponentsVersion !== localComponentsVersion) {
        console.log(`Local components are outdated, migrating from ${localComponentsVersion} to ${serverComponentsVersion}`)
        // Loop over all localComponents
        components = components.map(localComponent => migrate(localComponent, localComponentsVersion, serverComponentsVersion))
      }
    }

    // Filter cards by user permissions
    // const { checkForPermissions } = useUserPermissionsClient()
    // const { loggedIn, user } = useUserSession()

    // if (loggedIn.value) {
    //   components = components.filter(component => checkForPermissions(user?.value?.permissions, component.permission))
    // }

    return components
  },
  async addComponent ({ commit, dispatch, state }, payload) {
    if (!state.isInitialised) {
      await dispatch('initialise')
    }
    commit('addComponent', payload)
  },
}

export const mutations = {
  setComponents (state, payload) {
    state.components = payload
    if (state.useLocalStorage) {
      localStorageSet(payload)
    }
  },
  // This mutation expects an object containing an: key, prop, value
  updateComponentProp (state, payload) {
    // Check if index, prop and value not nullish
    if (!('key' in payload && 'prop' in payload && 'value' in payload)) {
      const error = 'Sorry, object not formatted properly'
      throw error
    }
    const { key, prop, value } = payload

    const index = state.components.findIndex(component => component.key === key)
    if (!state.components[index]) {
      const error = 'Sorry, component not found'
      throw error
    }
    state.components[index].props[prop] = value
    localStorageSet(state.components)
  },
  addComponent (state, payload) {
    // Random key to identify the component
    payload.key = Math.random().toString(36).substring(7)
    // If we don't copy the object, the component will be updated in the store when the user updates the component in the dashboard
    const copyOfPayload = cloneDeep(payload)
    state.components.unshift(copyOfPayload)
    localStorageSet(state.components)
    useToast().add({ title: 'Success', description: 'Card saved to dashboard', icon: 'i-heroicons-check-circle' })
  },
  removeComponent (state, payload) {
    console.log('Removing component', payload.key)
    const index = state.components.findIndex(component => component.key === payload.key)
    state.components.splice(index, 1)
    localStorageSet(state.components)
  },
  resetToDefaultLayout (state) {
    state.components = serverComponents
    localStorageSet(state.components)
  },
}

function localStorageSet (data) {
  localStorage.setItem(localStorageKey, JSON.stringify(data))
}

function localStorageGet (key) {
  return JSON.parse(localStorage.getItem(key))
}
