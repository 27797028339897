// import { dateAddWeeks, dateFormatYMD } from './dateFns.js';
// import { Base64 } from '../plugins/base64.js';
// import '../plugins/globalThis';

export const dumbClone = obj => JSON.parse(JSON.stringify(obj))

export const isBrowser = typeof window !== 'undefined'

export const isInternetExplorer = (globalThis?.navigator?.userAgent?.match?.(/MSIE|Trident/) !== null)

export const EventDispatcher = new Dispatcher()

export function Dispatcher () {
  const eventMap = new Map()

  return {
    addEventListener (event, callback) {
      const wrappedCallback = (evt) => {
        callback.apply(this, evt.detail)
      }
      eventMap.set(callback, wrappedCallback)
      document?.addEventListener?.(event, wrappedCallback)
    },

    removeEventListener (event, callback) {
      const wrappedCallback = eventMap.get(callback)
      if (wrappedCallback) {
        document?.removeEventListener?.(event, wrappedCallback)
        eventMap.delete(callback)
      }
    },

    dispatch (event) {
      document?.dispatchEvent?.(createCustomEvent(event, Array.prototype.slice.call(arguments, 1)))
    },
  }
}

/**
 * Sets the dbg cookie
 * @param {boolean} shouldOpen
 */
export function debugMenu (shouldOpen = 1) {
  shouldOpen = 1 * shouldOpen
  const cookieName = 'dbg'
  if (!shouldOpen) {
    eraseCookie(cookieName)
    // storedItemCreate('debug_menu_state', shouldOpen)
    return
  }
  // storedItemCreate('debug_menu_state', shouldOpen)
  setCookie(cookieName, shouldOpen)
  location.reload()
}

/**
 * Sets the force_server cookie
 * @param {number} serverId
 */
export function forceServer (serverId) {
  const cookieName = 'force_server'
  if (serverId === null) {
    eraseCookie(cookieName)
    return
  }
  setCookie(cookieName, serverId)
}

/**
 * Create a reactive property
 * @param {name} name - variable name
 * @param {initialValue} initialValue - the value to set to the variable
 * @param {updateCallback} updateCallback - the function to call when the variable has been updated
 * @param {context} context - where to bind the variable
 */
export function defineReactiveProperty (name, initialValue, updateCallback, context = this) {
  let currentValue = initialValue
  Object.defineProperty(context, name, {
    get () {
      return currentValue
    },
    set (newValue) {
      // Only run callback if value has changed, need better comparison to handle objects
      if (currentValue !== newValue) {
        currentValue = newValue
        updateCallback()
      } else {
        console.log('value set but not changed')
      }
    },
  })
  updateCallback()
}

/**
 * Returns the height of the document
 * @returns {number}
 */
export function getDocumentHeight () {
  const body = document.body
  const html = document.documentElement
  return Math.max(
    body.offsetHeight,
    body.scrollHeight,
    html.clientHeight,
    html.offsetHeight,
    html.scrollHeight,
  )
}

/**
 * Get the properly formatted tracking class name
 * @param {string} className the core class name
 * @returns the formatted class name
 */
export function getTrackingClass (className) {
  return `trck_${className}`
}

/**
 * Add 7 days
 * @param {string} strDate
 * @returns {string} Formatted "yyyy-mm-dd"
 */
// export function add7Days (strDate) {
//   return dateFormatYMD(dateAddWeeks(new Date(strDate), 1));
// }

/**
 * Add 14 days
 * @param {string} strDate
 * @returns {string} Formatted "yyyy-mm-dd"
 */
// export function add14Days (strDate) {
//   return dateFormatYMD(dateAddWeeks(new Date(strDate), 2));
// }

/**
 * Check if Safari
 * @returns {boolean} True if vendor or user agent matches the correct parameters
 */
export function isSafari () {
  return (
    navigator.vendor
    && navigator.vendor.includes('Apple')
    && navigator.userAgent
    && !navigator.userAgent.includes('CriOS')
    && !navigator.userAgent.includes('FxiOS')
  )
}

/**
 * Check if email is valid using regex
 * @param {string} email Email address to validate
 * @returns {boolean}
 */
export function isValidEmail (email) {
  return email.search(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i) != -1
}

/**
 * Prepend a zero for numbers lower then 10
 * @param {string|number} num
 * @returns {string}
 */
export function addZero (num) {
  return (`0${num}`).slice(-2)
}

/**
 * Get a cookie value
 * @param {string} name Cookie name to get
 * @returns {string|null}
 */
export function getCookie (name) {
  const nameEQ = `${name}=`
  const ca = document.cookie.split(';')

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]

    while (c.charAt(0) == ' ') {
      c = c.substring(1, c.length)
    }

    if (c.indexOf(nameEQ) === 0) {
      return c.substring(nameEQ.length, c.length)
    }
  }

  return null
}

/**
 * Set a cookie value
 * @param {string} name Name of cookie value to set
 * @param {string|number} value Value to set
 * @param {string|number} days Number of days the cookie should be valid
 * @param {string|number} seconds Number of days the cookie should be valid
 */
export function setCookie (name, value, days, seconds) {
  let expires = ''

  if (days) {
    const date = new Date()

    seconds = seconds ? seconds * 1000 : 0
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000 + seconds))

    expires = `; expires=${date.toGMTString()}`
  }

  document.cookie = `${name}=${value}${expires}; path=/`
}

/**
 * Remove a cookie
 * @param {string} name Cookie to remove
 */
export function eraseCookie (name) {
  setCookie(name, '', -1)
}

/**
 * Get URL parameters
 * @returns {string}
 */
export function getUrlVars (url = globalThis.location.href) {
  // const urlParams = new URLSearchParams(location.search);
  // const params = Object.fromEntries(urlParams);
  // return params
  const vars = {}
  url.replace(/[?&]+([^=&]+)=([^&|#]*)/gi, (
    m,
    key,
    value,
  ) => {
    vars[key] = value
  })

  return vars
}

/**
 * Update current URL with new parameters
 * @param {string} key
 * @param {string} value
 * @param {boolean} pushState - If true, use a new history entry will be created
 */
export function urlParameterSet (key, value, pushState = false) {
  if (!isBrowser) {
    return
  }
  const url = new URL(globalThis.location.href)
  url.searchParams.set(key, value)
  if (pushState) {
    globalThis.history.pushState({}, '', url)
  } else {
    globalThis.history.replaceState({}, '', url)
  }
  // update url without updating the history
}

/**
 *
 * @param {string} key
 * @param {boolean} pushState - If true, use a new history entry will be created
 */
export function urlParameterDelete (key, pushState) {
  if (!isBrowser) {
    return
  }
  const url = new URL(globalThis.location.href)
  url.searchParams.delete(key)
  if (pushState) {
    globalThis.history.pushState({}, '', url)
  } else {
    globalThis.history.replaceState({}, '', url)
  }
}

export function urlParameterGet (key) {
  if (!isBrowser) {
    return
  }
  const url = new URL(globalThis.location.href)
  return url.searchParams.get(key)
}

/**
 * Set a URL parameter
 * @param {string|number} url full URL string
 * @param {string|number} parameterName parameter name
 * @param {string|number} parameterValue parameter value to set
 * @param {boolean} atStart if the property should be added to the start of the url
 * @returns {string} full URL with the new parameter
 */
export function setUrlParameter (url, parameterName, parameterValue, atStart) {
  const replaceDuplicates = true
  let urlhash
  let cl
  if (url.indexOf('#') > 0) {
    cl = url.indexOf('#')
    urlhash = url.substring(url.indexOf('#'), url.length)
  } else {
    urlhash = ''
    cl = url.length
  }

  const sourceUrl = url.substring(0, cl)

  const urlParts = sourceUrl.split('?')
  let newQueryString = ''

  if (urlParts.length > 1) {
    const parameters = urlParts[1].split('&')

    for (let i = 0; i < parameters.length; i++) {
      const parameterParts = parameters[i].split('=')

      if (!(replaceDuplicates && parameterParts[0] === parameterName)) {
        if (newQueryString === '') {
          newQueryString = '?'
        } else {
          newQueryString += '&'
        }

        newQueryString
          += `${parameterParts[0]
           }=${
           parameterParts[1] ? parameterParts[1] : ''}`
      }
    }
  }

  if (newQueryString === '') {
    newQueryString = '?'
  }

  if (atStart) {
    newQueryString
      = `?${
       parameterName
       }=${
       parameterValue
       }${newQueryString.length > 1 ? `&${newQueryString.substring(1)}` : ''}`
  } else {
    if (newQueryString !== '' && newQueryString !== '?') {
      newQueryString += '&'
    }

    newQueryString
      += `${parameterName}=${parameterValue || ''}`
  }

  return urlParts[0] + newQueryString + urlhash
}

/**
 * Create a range of numbers from start to end with a given step
 * @param {number} start
 * @param {number} end
 * @param {number} step
 * @returns {Array}
 */
export function range (start, end, step) {
  const range_ = []
  const typeofStart = typeof start
  const typeofEnd = typeof end

  if (step === 0) {
    throw new TypeError('Step cannot be zero.')
  }

  if (typeofStart == 'undefined' || typeofEnd == 'undefined') {
    throw new TypeError('Must pass start and end arguments.')
  } else if (typeofStart != typeofEnd) {
    throw new TypeError('Start and end arguments must be of same type.')
  }

  typeof step === 'undefined' && (step = 1)

  if (end < start) {
    step = -step
  }

  if (typeofStart == 'number') {
    while (step > 0 ? end >= start : end <= start) {
      range_.push(start)
      start += step
    }
  } else if (typeofStart == 'string') {
    if (start.length != 1 || end.length != 1) {
      throw new TypeError('Only strings with one character are supported.')
    }

    start = start.charCodeAt(0)
    end = end.charCodeAt(0)

    while (step > 0 ? end >= start : end <= start) {
      range_.push(String.fromCharCode(start))
      start += step
    }
  } else {
    throw new TypeError('Only string and number types are supported')
  }

  return range_
}

/**
 * Converts seconds into hours and minutes
 * @param {number} traveltime - the traveltime in seconds
 * @returns {string} the traveltime in hours and minutes
 */
export function getTimeText (traveltime) {
  if (
    typeof traveltime === 'number'
    && traveltime != 0
    && traveltime != Number.MAX_VALUE
  ) {
    const time = String(traveltime / 60 / 60)
    const timeArr = time.split('.')
    let minutes = ''

    // in german formatting, add space before hours and minutes
    const germanSpacing = globalThis.js_params.language == 'de' ? ' ' : ''

    if (timeArr.length == 2) {
      minutes
      = ` ${
       Math.round(Number(`0.${String(timeArr[1])}`) * 60)
       }${germanSpacing
       }${l('time.minutesShort')}`
    }

    const hours = timeArr[0]

    return hours + germanSpacing + l('time.hoursShort') + minutes
  }

  return l('Saknas')
}

/**
 * Formats a price based on formating options (if none or only a few is provided, fallback to settings file)
   @param int price
   @param object formating

   @return string *
 */
export function lc (price, formating) {
  if (typeof formating === 'undefined' || formating === true) {
    formating = {}
  }

  for (const key in globalThis.js_params.defaultCurrencyFormating) {
    if (!formating.hasOwnProperty(key)) {
      formating[key] = globalThis.js_params.defaultCurrencyFormating[key]
    }
  }

  price = Number(price)

  // Now setup everything
  price = price.toFixed(formating.decimals)

  // Decimal sign
  if (formating.decimalSymbol !== '.') {
    price = price.replace('.', formating.decimalSymbol)
  }

  // Thousand seperators
  price = price
    .toString()
    .replace(
      /(\d)(?=(\d{3})+(?!\d))/g,
      `$1${formating.thousandSeperator}`,
    )

  // Currency-symbol spacing
  const posBefore = formating.currencySymbolPositionBefore
  const currencySpace = formating.currencySymbolSpace
  let preString = ''
  let postString = ''

  // Assign space and currencysymbol to correct place
  if (posBefore) {
    preString = formating.currencySymbol + (currencySpace ? ' ' : '')
  } else {
    postString = (currencySpace ? ' ' : '') + formating.currencySymbol
  }

  return preString + price + postString
}

/**
 *
 * @param {string} path to file
 * @param{Boolean} versionControl boolean if version number should be prepended
 * @returns {string}
 */
export function lm (path, language = 'en') {
  path = path.split('/')
  const filename = `${language}_${path.pop()}`

  return `${path.join('/')}/${filename}`
}

/**
 * Get the translated text for the specified translation key
 * @param {string} key the translation key
 * @param {string} str fallback string
 * @returns {string}
 */
export function l (key, str) {
  console.error('l is deprecated, use $t instead', key)
  return key
  // if (globalThis.js_localized[key]) {
  //   return globalThis.js_localized[key]
  // }

  // if (globalThis.js_params.debug) {
  //   EventDispatcher.dispatch('translation.missingKey', key)
  //   // console.log("Missing localization for: " + key);
  // }

  // if (typeof globalThis.localizationMissing === 'undefined') {
  //   globalThis.localizationMissing = {}
  // }

  // globalThis.localizationMissing[key] = 1

  // return str || key
}

/**
 * Get the translated text for the specified key inserting variables
   @param  lkey         string - the translation key
   @param  dictionary   string or object - the value or the dictionnary of variables name and their values to insert
   @deprecated
   @return the translated text *
 */
export function lreplace (lText, dictionary) {
  for (const key in dictionary) {
    // if key is part of prototype
    if (!dictionary.hasOwnProperty(key)) {
      continue
    }

    lText = lText.replace(new RegExp(`\\$${key}`, 'g'), dictionary[key])
  }

  return lText
}

/**
 *
 * @param {object} args Expected an object containing two strings: code and size
 * @returns {string} URL to agency image
 */
export function getAgencyImagePath (args) {
  let { code, size } = args
  const { domainSpecificAgencyLogos, version } = globalThis.js_params

  const codeSplit = code.split('_')
  let domain = 'default'

  size = size || 'regular'

  if (domainSpecificAgencyLogos) {
    if (domainSpecificAgencyLogos.includes(codeSplit[1])) {
      domain = codeSplit[0]
    }
  }

  return `/static/v${version}/images/logos_agency/${size}/${domain}/${codeSplit[1]}.png`
}

/**
 * Get the formatted config for the Searchbox from URL variables
   @param  urlVars   object - the dictionary of available URL variables

   @return the searchbox config object in the proper format to instantiate the searchbox fields *
 */
export function getSearchBoxConfigFromUrl (urlVars) {
  const searchBoxConfig = {}

  if ('initSearch' in globalThis.js_params && globalThis.js_params.initSearch.length != 0) {
    searchBoxConfig.legs = []
    const initSearch = globalThis.js_params.initSearch
    const legs = {}

    if ('from' in initSearch) {
      legs.from = initSearch.from
      legs.fromIata = initSearch.fromIata
      legs.fromMetro = initSearch.fromMetro
      legs.fromIatas = [
        {
          iata: initSearch.fromIata,
          text: initSearch.from,
          isMetro: initSearch.fromMetro,
        },
      ]
    }

    if ('to' in initSearch) {
      legs.to = initSearch.to
      legs.toIata = initSearch.toIata
      legs.toMetro = initSearch.toMetro
      legs.toIatas = [
        {
          iata: initSearch.toIata,
          text: initSearch.to,
          isMetro: initSearch.toMetro,
        },
      ]
    }

    if (urlVars.dateFrom) {
      legs.departDate = urlVars.dateFrom
    }

    if (urlVars.dateTo) {
      legs.returnDate = urlVars.dateTo
    }

    searchBoxConfig.legs.push(legs)
  }

  if ('adults' in urlVars) {
    searchBoxConfig.passengers = {}

    searchBoxConfig.passengers.adults = Number.parseInt(urlVars.adults)
    searchBoxConfig.passengers.child = {
      count: urlVars.children ? Number.parseInt(urlVars.children) : 0,
      ages: urlVars.childrenAges ? urlVars.childrenAges.split(',').map(str => Number.parseInt(str)) : [],
    }
  }

  // Not originating from widget searchbox
  if ('youth' in urlVars) {
    searchBoxConfig.passengers = {}

    searchBoxConfig.passengers.youth = {
      count: Number.parseInt(urlVars.youth),
      ages: ('youthAges' in urlVars) ? urlVars.youthAges.split(',').map(str => Number.parseInt(str)) : [],
    }
  }

  return searchBoxConfig
}

/**
 * Filter function for non iterable object based on key values
   @param  obj       object - object to filter within
   @param  callback   function - filtering function to apply on key values
 */
export function filterObjectByKeys (obj, callback) {
  if (!obj) {
    throw new TypeError('obj must not be null')
  }
  if (typeof obj !== 'object') {
    throw new TypeError('obj must be of type array')
  }
  if (typeof callback !== 'function') {
    throw new TypeError('callback must be a function')
  }

  const objAsArray = Object.entries(obj)
  const filtered = objAsArray.filter(([key, value]) => callback(key))

  // No full support for Object.fromEntries at the moment (IE, Opera mini)
  const filteredAsObject = {}
  filtered.forEach((entry) => {
    filteredAsObject[entry[0]] = entry[1]
  })

  return filteredAsObject
}

/**
 * Add or remove items with a certain value from a primitive or complex array
 * @param {Array} array array to toggle items in
 * @param {string|number|object} itemToToggle item to toggle
 * @param {callback} [getValue] function that returns the value of item to toggle, only relevant for objects
 * @returns {Array} a new array with the item either added or removed
 * @example
 * const myArrayOfStrings = ['Tristan', 'Valle']
 * toggleArray(myArrayOfStrings, 'Tristan') // ['Valle']
 * const myArrayOfObjects = [{name: 'Valle', age: 49}, {name: 'Tristan', age: 31}]
 * toggleArray(myArrayOfObjects, {name: 'Tristan', age: 31}, item => `${item.name}|item.age`) // [{name: 'Valle', age: 49}]
 */
export function arrayToggleValue (array, itemToToggle, getValue = item => item) {
  const index = array.findIndex(item => getValue(item) === getValue(itemToToggle))
  if (index === -1) {
    return [...array, itemToToggle]
  }
  return removeAtIndex(array, index)
}

/**
 * Immutable remove an item from an array at a given index
 * @param {Array} array array to remove item from
 * @param {number} index index of item to remove
 * @param {number} [numberOfItemsToRemove] number of items to remove
 * @returns {Array} a new array with the item removed
 */
export function removeAtIndex (array, index, numberOfItemsToRemove = 1) {
  const copy = [...array]
  copy.splice(index, numberOfItemsToRemove)
  return copy
}

/**
 * Encode a set of form elements as an array of names and values.
 * @param {Element} form
 * @returns {Array}
 */
export function arraySerialize (form) {
  let field; let l; const s = []
  if (typeof form === 'object' && form.nodeName === 'FORM') {
    const len = form.elements.length
    for (let i = 0; i < len; i++) {
      field = form.elements[i]
      if (field.name && !field.disabled && field.type !== 'file' && field.type !== 'reset' && field.type !== 'submit' && field.type !== 'button') {
        if (field.type === 'select-multiple') {
          l = form.elements[i].options.length
          for (let j = 0; j < l; j++) {
            if (field.options[j].selected) {
              s[s.length] = { name: field.name, value: field.options[j].value }
            }
          }
        } else if ((field.type !== 'checkbox' && field.type !== 'radio') || field.checked) {
          s[s.length] = { name: field.name, value: field.value }
        }
      }
    }
  }
  return s
}

/**
 * Does what it says on the box ^_^
 * @param {any} variableToCheck
 * @returns {boolean}
 */
export function isEmptyObject (variableToCheck) {
  return Object.keys(variableToCheck || {}).length === 0 && variableToCheck?.constructor === Object
}

/**
 * Does what it says on the tin =]
 * @param {callback} callback
 * @param {number} wait
 * @returns
 */
export function debounce (callback, wait) {
  let timeout
  return (...args) => {
    const context = this
    clearTimeout(timeout)
    timeout = setTimeout(() => callback.apply(context, args), wait)
  }
}

export function isVariableEmpty (value) {
  const typeOfValue = typeof value
  switch (typeOfValue) {
    case 'boolean':
    case 'string':
    case 'number':
      return false
    case 'object':
      if (value === null) {
        return false
      } else if (Array.isArray(value) && value.length > 0) {
        return false
      } else if (Object.keys(value).length > 0) {
        return false
      }
      return true
    default:
      return true
  }
}

/**
 * Attach the intersection observer to an element
 * Run a callback when that element scrolls into view
 * @param {Element} element dom node - the element to bind to
 * @param {callback} callback function to call when the element scrolls into view
 * @param {object} intersectionOptions options to pass to the intersection observer
 * @return none
 */
export function bindIntersectionObserver (element, callback, intersectionOptions) {
  const observer = new IntersectionObserver(callback, intersectionOptions)
  observer.observe(element)
}

/**
 *
 * @param {string} str
 * @returns {string}
 */
export function removeDotFromString (str) {
  return str.replace(/\./g, '')
}

/**
 * Generates a psuedo random string
 * @param {number} length - Max length 15
 * @returns {string}
 */
export function genUID (length = -8) {
  return Math.random().toString(16).slice(length)
}

/**
 * Checks an objets messages matches a set of strings
 * @param {Error} error
 * @returns {boolean}
 */
export function isNetworkError (error) {
  const networkErrorStrings = ['NetworkError', 'Load failed', 'Failed to fetch']
  return networkErrorStrings.some(networkErrorString => error?.message?.includes?.(networkErrorString))
}

/**
 * @param {object} element
 * @returns {boolean}
 */
export function isError (obj) {
  return Object.prototype.toString.call(obj) === '[object Error]'
}

export function debugTranslationKeys (isVisible) {
  if (isVisible) {
    setCookie('showTranslationKeys', isVisible)
  } else {
    eraseCookie('showTranslationKeys')
  }
  location.reload()
}

export function overrideTranslationFunctions () {
  l = key => key

  lm = (key, count) => key

  lreplace = (key, replace) => `${key} - ${JSON.stringify(replace)}`
}

export function isAbortError (error) {
  return error?.name === 'AbortError'
}

export function decimalToBinary (dec) {
  return (dec >>> 0).toString(2)
}

/**
 *
 * @param {number} start
 * @returns {number}
 */
export function timeExecution (start) {
  if (!start) {
    return Date.now()
  }
  return Math.round(Date.now() - start)
}

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~OBJECTS~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

/**
 *
 * @param {object} filterObject object to clean
 * @param {Array} keyWhitelist a series of keys to keep even if they are empty
 * @returns {object}
 */
export function objectRemoveEmptyEntries (filterObject, keyWhitelist = []) {
  // Get only the keys we are interested in
  const filterKeys = Object.keys(keyWhitelist.length ? keyWhitelist : filterObject)

  // Create a clean object that will contain the filter keys
  const cleanObject = {}

  // Loop over all entries in the store, filter the keys we are interested in
  Object.entries(filterObject).filter(([key]) => filterKeys.includes(key)).forEach(([key, value]) => {
    // If not an empty array/object then add it to `cleanObject`
    if (!isVariableEmpty(value)) {
      cleanObject[key] = value
    }
  })
  return cleanObject
}
/**
 * @description Converts an object to a query string
 * @param {object} obj
 * @returns
 */
export function objectToQueryString (obj) {
  return Object.keys(obj).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`).join('&')
}

/**
 * Insert an object into another object if a condition is met
 * @param {boolean} condition
 * @param {object} objectToInsert
 * @returns {object}
 */
export function insertIf (condition, objectToInsert) {
  const returnType = Array.isArray(objectToInsert) ? [] : typeof objectToInsert === 'object' ? {} : null
  return condition ? objectToInsert : returnType
}

/**
 *
 * @param {object} keysToSwap
 * @param {object} oldItem
 * @example - swapKeysObj(obj, {new: 'old'})
 * const obj1 = { to_city_id: 'id', to_city_name: 'name' };
 * const obj2 = { id: 1, name: 'New York' };
 */
export function swapKeysObj (oldItem, keysToSwap) {
  const newObj = JSON.parse(JSON.stringify(oldItem))
  Object.entries(keysToSwap).forEach(([newKey, oldKey]) => {
    newObj[newKey] = oldItem[oldKey]
    delete newObj[oldKey]
  })
  return newObj
}

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ARRAYS~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

/**
 *
 * @param {Array} arrayOfItems
 * @param {object} keysToSwap
 * @returns {Array}
 * @example swapKeys([{id: 1, name: 'New York'}], {to_city_id: 'id', to_city_name: 'name'})
 */
export function swapKeys (arrayOfItems, keysToSwap) {
  if (Array.isArray(arrayOfItems)) {
    return arrayOfItems.map(item => swapKeysObj(item, keysToSwap))
  } else if (typeof arrayOfItems === 'object') {
    return swapKeysObj(arrayOfItems, keysToSwap)
  }
}

export function arrayMove (arr, fromIndex, toIndex) {
  const element = arr[fromIndex]
  arr.splice(fromIndex, 1)
  arr.splice(toIndex, 0, element)
}

export function arrayMoveImmutable (arr, fromIndex, toIndex) {
  if (fromIndex === toIndex) {
    return arr
  }

  const newArr = [...arr]
  const element = newArr[fromIndex]

  newArr.splice(fromIndex, 1)
  newArr.splice(toIndex, 0, element)

  return newArr
}

export function arrayCompare (a, b) {
  return a.length === b.length && a.every((element, index) => element === b[index])
}

/**
 * Get the last element of an array or null
 * @param {Array} array
 * @returns {any|null} last element of the array
 */
export function last (array) {
  return array?.[array.length - 1] || null
}

/**
 * Shuffle an array
 * @param {Array} array Array to shuffle
 * @returns {Array} New array shuffled
 */
export function arrayShuffle (arr) {
  return arr
    .map(a => [Math.random(), a])
    .sort((a, b) => a[0] - b[0])
    .map(a => a[1])
}

/**
 * Transform an array of object into an array of strings
 * @param {Array} arr
 * @returns {Array}
 */
export function arrayMultiDimensionalUnique (arr) {
  const uniques = []
  const itemsFound = {}
  const arrLength = arr.length

  for (let i = 0; i < arrLength; i++) {
    const stringified = JSON.stringify(arr[i])

    if (itemsFound[stringified]) {
      continue
    }

    uniques.push(arr[i])

    itemsFound[stringified] = true
  }

  return uniques
}

export function kebabCaseUrl (string) {
  return convertToAscii(string).toLowerCase().replace(/ /g, '-')
}

function convertToAscii (string) {
  const charMap = { À: 'A', Á: 'A', Â: 'A', Ã: 'A', Ä: 'A', Å: 'A', Æ: 'AE', Ç: 'C', È: 'E', É: 'E', Ê: 'E', Ë: 'E', Ì: 'I', Í: 'I', Î: 'I', Ï: 'I', Ð: 'D', Ñ: 'N', Ò: 'O', Ó: 'O', Ô: 'O', Õ: 'O', Ö: 'O', Ø: 'O', Ù: 'U', Ú: 'U', Û: 'U', Ü: 'U', Ý: 'Y', ß: 's', à: 'a', á: 'a', â: 'a', ã: 'a', ä: 'a', å: 'a', æ: 'ae', ç: 'c', è: 'e', é: 'e', ê: 'e', ë: 'e', ì: 'i', í: 'i', î: 'i', ï: 'i', ñ: 'n', ò: 'o', ó: 'o', ô: 'o', õ: 'o', ö: 'o', ø: 'o', ù: 'u', ú: 'u', û: 'u', ü: 'u', ý: 'y', ÿ: 'y', Ā: 'A', ā: 'a', Ă: 'A', ă: 'a', Ą: 'A', ą: 'a', Ć: 'C', ć: 'c', Ĉ: 'C', ĉ: 'c', Ċ: 'C', ċ: 'c', Č: 'C', č: 'c', Ď: 'D', ď: 'd', Đ: 'D', đ: 'd', Ē: 'E', ē: 'e', Ĕ: 'E', ĕ: 'e', Ė: 'E', ė: 'e', Ę: 'E', ę: 'e', Ě: 'E', ě: 'e', Ĝ: 'G', ĝ: 'g', Ğ: 'G', ğ: 'g', Ġ: 'G', ġ: 'g', Ģ: 'G', ģ: 'g', Ĥ: 'H', ĥ: 'h', Ħ: 'H', ħ: 'h', Ĩ: 'I', ĩ: 'i', Ī: 'I', ī: 'i', Ĭ: 'I', ĭ: 'i', Į: 'I', į: 'i', İ: 'I', ı: 'i', Ĳ: 'IJ', ĳ: 'ij', Ĵ: 'J', ĵ: 'j', Ķ: 'K', ķ: 'k', Ĺ: 'L', ĺ: 'l', Ļ: 'L', ļ: 'l', Ľ: 'L', ľ: 'l', Ŀ: 'L', ŀ: 'l', Ł: 'L', ł: 'l', Ń: 'N', ń: 'n', Ņ: 'N', ņ: 'n', Ň: 'N', ň: 'n', ŉ: 'n', Ō: 'O', ō: 'o', Ŏ: 'O', ŏ: 'o', Ő: 'O', ő: 'o', Œ: 'OE', œ: 'oe', Ŕ: 'R', ŕ: 'r', Ŗ: 'R', ŗ: 'r', Ř: 'R', ř: 'r', Ś: 'S', ś: 's', Ŝ: 'S', ŝ: 's', Ş: 'S', ş: 's', Š: 'S', š: 's', Ţ: 'T', ţ: 't', Ť: 'T', ť: 't', Ŧ: 'T', ŧ: 't', Ũ: 'U', ũ: 'u', Ū: 'U', ū: 'u', Ŭ: 'U', ŭ: 'u', Ů: 'U', ů: 'u', Ű: 'U', ű: 'u', Ų: 'U', ų: 'u', Ŵ: 'W', ŵ: 'w', Ŷ: 'Y', ŷ: 'y', Ÿ: 'Y', Ź: 'Z', ź: 'z', Ż: 'Z', ż: 'z', Ž: 'Z', ž: 'z', ſ: 's', ƒ: 'f', Ơ: 'O', ơ: 'o', Ư: 'U', ư: 'u', Ǎ: 'A', ǎ: 'a', Ǐ: 'I', ǐ: 'i', Ǒ: 'O', ǒ: 'o', Ǔ: 'U', ǔ: 'u', Ǖ: 'U', ǖ: 'u', Ǘ: 'U', ǘ: 'u', Ǚ: 'U', ǚ: 'u', Ǜ: 'U', ǜ: 'u', Ǻ: 'A', ǻ: 'a', Ǽ: 'AE', ǽ: 'ae', Ș: 'S', ș: 's' }
  return string.split('').map(char => charMap[char] || char).join('')
}

/**
 * Sleep for a given amount of time
 * @param {number} ms number of milliseconds to sleep
 * @returns {Promise} resolves when the number of milliseconds requested has passed
 */
export function sleep (ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}
