export function state () {
  return {
    isCustomisable: false,
    isSidebarMini: true,
  }
}

export const mutations = {
  isSidebarMini (state, payload) {
    state.isSidebarMini = payload
  },
  toggleSidebarMini (state) {
    state.isSidebarMini = !state.isSidebarMini
  },
  isCustomisable (state, payload) {
    state.isCustomisable = payload
  },
  toggleCustomisable (state) {
    state.isCustomisable = !state.isCustomisable
  },
}
