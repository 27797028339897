import { useUserPermissionsClient } from '~~/sharedUtils/useUserPermissionsClient'

export default defineNuxtRouteMiddleware(async (to, from) => {
  const { public: { authEnabled } } = useRuntimeConfig()

  // Early return if auth is not enabled
  if (!authEnabled) {
    return
  }

  const { loggedIn, user } = useUserSession()
  const { checkForPermissions, permissionTypes } = useUserPermissionsClient()

  // Define allowed routes based on user status
  const routesAccess = {
    anonymous: ['/login', '/401', '/api/auth/google'],
  }

  // Non-logged in users
  if (!loggedIn.value) {
    // Non-logged in and trying to access a protected route
    if (!routesAccess.anonymous.includes(to.path)) {
      return navigateTo(`/login?url=${encodeURIComponent(to.path)}`, { query: { url: encodeURIComponent(to.path) } })
    }
    return
  }

  // Extract user permissions for further checks
  const userPermissions = user?.value?.permissions || 0

  // Determine if the user is an admin
  const isGuest = checkForPermissions(userPermissions, permissionTypes.guest)
  const isUser = checkForPermissions(userPermissions, permissionTypes.user)
  const isAdmin = checkForPermissions(userPermissions, permissionTypes.admin)

  if (isAdmin || isUser) {
    return
  }

  if (isGuest && to.path !== '/401') {
    return navigateTo('/401', {
      redirectCode: 401,
    })
  }
})
