/* eslint-disable */
import { throttle } from 'lodash-es'

// these regular expressions are borrowed from below page.
// https://stackoverflow.com/questions/11381673/detecting-a-mobile-browser


const REGEX_MOBILE1 = /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i
const REGEX_MOBILE2 = /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i
function isMobile (a) { return REGEX_MOBILE1.test(a) || REGEX_MOBILE2.test(a.substr(0, 4)) }
const REGEX_MOBILE_OR_TABLET1 = /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i
const REGEX_MOBILE_OR_TABLET2 = /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i
function isMobileOrTablet (a) { return REGEX_MOBILE_OR_TABLET1.test(a) || REGEX_MOBILE_OR_TABLET2.test(a.substr(0, 4)) }
function isIos (a) { return /iPad|iPhone|iPod/.test(a) }
function isAndroid (a) { return /android/i.test(a) }
function isWindows (a) { return /Windows/.test(a) }
function isMacOS (a) { return /Mac OS X/.test(a) }
const DEFAULT_USER_AGENT = 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_13_2) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/64.0.3282.39 Safari/537.36'

const defaultOptions = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1220,
  options: {
    polyfill: true,
    throttle: 300
  }
}

const defaultBreakpoints = {
  current: 'xs',

  xs: false,

  sm: false,
  lSm: false,
  sSm: false,

  md: false,
  lMd: false,
  sMd: false,

  lg: false,
  lLg: false,
  sLg: false,

  sXl: false,
  xl: false,

  isIos: false,
  isAndroid: false,
  isWindows: false,
  isMacOS: false,

  width: 0,
  height: 0
}

export default defineNuxtPlugin(async (nuxtApp) => {
  const options = defaultOptions
  const headers = useRequestHeaders()
  const breakpoints = reactive(defaultBreakpoints)
    // Try to guess view port from headers

  let userAgent = ''
  if (!isEmptyObject(headers)) {
    userAgent = headers['user-agent']
  } else if (typeof navigator !== 'undefined') {
    userAgent = navigator.userAgent
  } else {
    userAgent = DEFAULT_USER_AGENT
  }
  // use default user-agent if user-agent header is not sent
  if (!userAgent) {
    userAgent = DEFAULT_USER_AGENT
  }

  if (userAgent === 'Amazon CloudFront') {
    if (headers['cloudfront-is-mobile-viewer'] === 'true') {
      breakpoints.sSm = true
      breakpoints.sm = true
    }
    if (headers['cloudfront-is-tablet-viewer'] === 'true') {
      breakpoints.sMd = true
      breakpoints.md = true
    }
  } else if (headers && headers['cf-device-type']) { // Cloudflare
    switch (headers['cf-device-type']) {
      case 'mobile':
        breakpoints.sSm = true
        breakpoints.sm = true
        break
      case 'tablet':
        breakpoints.sMd = true
        breakpoints.md = true
        break
      case 'desktop':
        breakpoints.lMd = true
        breakpoints.lg = true
        break
    }
  } else {
    breakpoints.isIos = isIos(userAgent)
    breakpoints.isAndroid = isAndroid(userAgent)
    // Mozilla/5.0 (Linux; Android 6.0; Nexus 5 Build/MRA58N) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/87.0.4280.66 Mobile Safari/537.36
    const mobile = isMobile(userAgent)
    const mobileOrTablet = isMobileOrTablet(userAgent)
    const tablet = !mobile && mobileOrTablet
    const desktop = !mobileOrTablet
    const size = { width: 0, height: 0 }
    if (desktop) {
      size.width = defaultOptions.xl + 1
    }
    if (tablet) {
      size.width = defaultOptions.md
    }
    if (mobile) {
      size.width = defaultOptions.sm
    }
    transformBreakpoints(breakpoints, size, options)
  }
  breakpoints.isWindows = isWindows(userAgent)
  breakpoints.isMacOS = isMacOS(userAgent)


  const isBrowser = typeof window !== 'undefined';
  if(isBrowser) {
    const needPolyfill =
      !Object.prototype.hasOwnProperty.call(window, 'ResizeObserver') &&
      options.options.polyfill

    if (needPolyfill) {
      const ResizeObserver = await import('resize-observer-polyfill')

      Object.defineProperty(window, 'ResizeObserver', {
        value: ResizeObserver.default,
        writable: false
      })
    }
    const bodyElem = document.querySelector('body')

    const resizeObserver = new ResizeObserver(
      throttle((entries) => {
        const [{ contentRect }] = entries
        transformBreakpoints(breakpoints, contentRect, options)
      }, +options.options.throttle, { trailing: true, leading: false })
    )


    resizeObserver.observe(bodyElem)
  }

  nuxtApp.provide('breakpoints', breakpoints)
  // inject('breakpoints', breakpoints)
})

function transformBreakpoints  (breakpoints, { width, height }, options) {
  const { sm, md, lg, xl } = options

  const breakpointRoles = { xs: 0, sm: 1, md: 2, lg: 3, xl: 4 }

  let currentActive = 'xs'

  switch (true) {
    case width >= xl:
      currentActive = 'xl'
      break
    case width >= lg:
      currentActive = 'lg'
      break
    case width >= md:
      currentActive = 'md'
      break
    case width >= sm:
      currentActive = 'sm'
      break
    default:
      currentActive = 'xs'
      break
  }

  const decideLargeOrSmallThan = (point, large = false) =>
    // large
    //   ? breakpointRoles[point] <= breakpointRoles[currentActive]
    //   : breakpointRoles[point] >= breakpointRoles[currentActive]
    large
      ? breakpointRoles[point] < breakpointRoles[currentActive]
      : breakpointRoles[point] > breakpointRoles[currentActive]

  const transformData = {
    sm: currentActive === 'sm',
    lSm: decideLargeOrSmallThan('sm', true),
    sSm: decideLargeOrSmallThan('sm'),

    md: currentActive === 'md',
    lMd: decideLargeOrSmallThan('md', true),
    sMd: decideLargeOrSmallThan('md'),

    lg: currentActive === 'lg',
    lLg: decideLargeOrSmallThan('lg', true),
    sLg: decideLargeOrSmallThan('lg'),

    xl: currentActive === 'xl',
    sXl: decideLargeOrSmallThan('xl'),
  }

  Object.assign(breakpoints, transformData, {
    current: currentActive,
    xs: currentActive === 'xs',
    xl: currentActive === 'xl',
    width: ~~width,
    height: ~~height
  })
}
