export const actions = {
  nuxtServerInit ({ dispatch, commit }, { req, app }) {
    if (app.$breakpoints.lLg) {
      commit('ui/isSidebarMini', false)
    }
  },
  nuxtClientInit ({ dispatch }, { req }) {
    dispatch('dynamicComponents/initialise')
  },
}
