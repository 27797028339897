export function state () {
  return {
    isModalOpen: false,
    modalComponent: null,
    modalTransition: 'fade-slow',
    modalClass: '',
    modalProps: {},
  }
}

export const mutations = {
  isModalOpen (state, payload) {
    state.isModalOpen = payload
  },
  open (state, payload) {
    state.modalComponent = payload.component
    state.modalTransition = payload.transition || 'fade-slow'
    state.modalProps = payload.props || {}
    state.modalClass = payload.class || ''
    state.isModalOpen = true
  },
}

export const actions = {
  // openModal ({ commit }, payload) {
  //   commit('modalOpen', payload)
  // }
}
