// import Vue from 'vue'
// import axios from 'axios'
import dataSources from '~~/sharedUtils/dataSources'

export function state () {
  return {
    data: {},
    intervals: {},
    isLoading: true,
  }
}

export const mutations = {
  data (state, payload) {
    state.data = payload
  },
  // object = { key: 'EBITDA_actual', value: { 2020: 1337 } }
  updateDataByKey (state, payload) {
    // Vue.set(state.data, payload.key, payload.value)
    console.log('updateDataByKey', payload)
  },
  isLoading (state, payload) {
    state.isLoading = payload
  },
}

export const actions = {
  // Accepts a object that will be passed to connectApi via getEndpointData({data: 'EBITDA_actual', year: '2020'})
  // Returns a promise
  async createEndpointDataInterval ({ commit, dispatch }, object) {
    if (!('data' in object)) {
      const error = 'Sorry, object not formatted properly'
      throw error
    }
    const dataSource = dataSources[object.data]
    if (dataSource.intervalHandle) {
      clearInterval(dataSource.intervalHandle)
    }
    dataSource.intervalHandle = setInterval(() => {
      dispatch('getEndpointData', object)
    }, dataSource.intervalTime)
    const resp = await dispatch('getEndpointData', object)
    if (typeof resp === 'object') {
      commit('updateDataByKey', { key: dataSource.apiKey, value: resp[dataSource.apiKey] })
    } else {
      const error = new Error(`The request for "${dataSource.apiKey}" failed`)
      throw error
    }
  },
  // expects an object: {apiTarget: 'csm', apiMethod: 'all', apiParams: '2020'}
  async getEndpointData ({ commit, state }, object) {
    // console.log(object.apiTarget, object.apiMethod, object.apiParams)
    const { apiTarget, apiMethod } = dataSources[object.data]
    object.data = apiMethod
    const nuxtApp = useNuxtApp()
    const resp = await nuxtApp.$connectApi('request', apiTarget, [object])
    if (typeof resp === 'object') {
      return resp
    } else {
      const error = new Error(`The request for "${apiTarget}" ${apiMethod} failed`)
      throw error
    }
  },
}
