export default defineAppConfig({
  ui: {
    primary: 'brandBlue',
    // gray: 'white',
    // container: {
    //   constrained: 'max-w-5xl',
    // },
    // uiMenu: {
    //   option: {
    //     size: 'text-base',
    //   },
    // },
  },
})
