import { format as dateFnsFormat } from 'date-fns'
import { enGB } from 'date-fns/locale'

export default defineNuxtPlugin((nuxtApp) => {
  // nuxtApp.provide('connectApi', connectApi)
  function format (date, format) {
    return dateFnsFormat(date, format, { locale: enGB })
  }

  nuxtApp.provide('format', format)
})
