export function useUserPermissionsClient () {
  const ADMIN = 1 << 10
  const USER = 1 << 5
  const GUEST = 1 << 3

  const permissionTypes = {
    guest: GUEST, // 2
    user: USER | GUEST, // 40
    admin: ADMIN | USER | GUEST, // 1064
  }

  function checkForPermissions (userPermissions: number, permission: number) {
    return (userPermissions & permission) === permission
  }

  function getUserType (userPermissions: number) {
    if (checkForPermissions(userPermissions, permissionTypes.admin)) {
      return 'Admin'
    } else if (checkForPermissions(userPermissions, permissionTypes.user)) {
      return 'User'
    } else {
      return 'Guest'
    }
  }

  return { checkForPermissions, permissionTypes, getUserType }
}
