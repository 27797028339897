export async function connectApi (target, method, parameters) {
  const { backendUrl } = useRuntimeConfig().public
  const body = { target, method, parameters }
  const resp = await $fetch(backendUrl, { method: 'POST', body })

  // Try to parse the response as JSON
  try {
    return JSON.parse(resp)
  } catch (error) {
    // If it fails, return the response as is
    return resp
  }
}
